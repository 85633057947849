export const marketplaceAddress = "0x660C56a3f42D9527324af8A51CBe78E8f2Db17aE";
export const auctionAddress = "0xFA2238eEb4E2E5612853BF29f925a36f971F9010";

export const manifoldOpenEditionAddress =
  "0x26BBEA7803DcAc346D5F5f135b57Cf2c752A02bE";

export const contractAddress = "0xe8A6e0d825BdB16Bb9CB4462484a1165Ef7C1A05";

export const artistId = 1294;
export const artistAddress = "0xfe99460b27bd6d4a3e8adb29bdf038be6684dd77";

export const projectName = "Mindscapes: The Visual Language of Jake Fried";
export const projectLink = "https://aotmepisode2.ninfa.io";

export const etherscanUrl = "https://etherscan.io";
export const basescanUrl = "https://basescan.org";

export const startingDate = 1721664000000;

export const artistName = "AOTM X NINFA";
export const bio = [
  "Art of This Millennium is a global gallery and publishing house specializing in contemporary digital art.",
  "From curation, to promotion and strategy, to exhibitions and sales, to legacy planning, we work hand in hand with our artists every step of the way from ideation to ultimately placing works with top patrons. Our publishing house produces supporting content to help contextualize our artists’ work and magnify their social significance. ",
];

export const ninfaUrl = "https://ninfa.io";

export const artistUrl = "";
export const artistTwitter = "https://x.com/AOTMgallery";
export const artistInsta = "https://www.instagram.com/aotmgallery/";

export const interviewPath = "";
export const articlePath = "";
export const standard = "ERC-721";
export const mintedOn = "Transient Labs";
export const totalPieces = "8";

export const validChainId = 1;

export const openEdition = {
  title: "Placeholder",
  description: "",
  price: 0,
  instanceId: 0,
  contractAddress: "0x0B4B102742A68B422B02563CFe226a3ba2bA3420",
  tokenId: 1,
  originalImageUrl: "",
  metadata: "",
  startDate: 1715097600,
  endDate: 1715184000,
} as const;

export const manifoldFee = 0.0005;

export const compressedImageBaseUrl = "https://images.ninfa.io/nfts/original";
